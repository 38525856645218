<template>
  <div style="width: 100%;text-align: center">

    <ul class="mo-paging">
      <!-- prev -->
      <li
          :class="['paging-item', 'paging-item--prev', {'paging-item--disabled' : index === 1}]"
          @click="prev">prev
      </li>

      <!-- first -->
      <li
          :class="['paging-item', 'paging-item--first', {'paging-item--disabled' : index === 1}]"
          @click="first">first
      </li>

      <li
          :class="['paging-item', 'paging-item--more']"
          v-if="showPrevMore">...
      </li>

      <li
          :class="['paging-item', {'paging-item--current' : index == pager}]"
          v-for="pager in pagers"
          @click="go(pager)">
          {{ pager }}

      </li>

      <li
          :class="['paging-item', 'paging-item--more']"
          v-if="showNextMore">...
      </li>

      <!-- last -->
      <li
          :class="['paging-item', 'paging-item--last', {'paging-item--disabled' : index === pages}]"
          @click="last">last
      </li>

      <!-- next -->
      <li
          :class="['paging-item', 'paging-item--next', {'paging-item--disabled' : index === pages}]"
          @click="next">next
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name : 'Pagination',
    props : {

      //화면에 보여지는 숫자，나머지는 ...으로 대체, 필이홀수
      perPages : {
        type : Number,
        default : 5
      },

      //현재페이지
      pageIndex : {
        type : Number,
        default : 1
      },

      //페이지당 보여지는 갯수
      pageSize : {
        type : Number,
        default : 20
      },

      //총기록수
      total : {
        type : Number,
        default : 1
      },

    },
    methods : {
      prev(){
        if (this.index > 1) {
          this.go(this.index - 1)
        }
      },
      next(){
        if (this.index < this.pages) {
          this.go(this.index + 1)
        }
      },
      first(){
        if (this.index !== 1) {
          this.go(1)
        }
      },
      last(){
        if (this.index !== this.pages) {
          this.go(this.pages)
        }
      },
      go (page) {
        if (this.index !== page) {
          this.index = page
          //parent Comp change
          this.$emit('change', this.index)
        }
      }
    },
    computed : {

      //총페이지 계산
      pages(){
        return Math.ceil(this.size / this.limit)
      },
      pagers () {
        const array = []
        const perPages = this.perPages
        const pageCount = this.pages
        let current = this.index
        const _offset = (perPages - 1) / 2


        const offset = {
          start : current - _offset,
          end   : current + _offset
        }

        //-1, 3
        if (offset.start < 1) {
          offset.end = offset.end + (1 - offset.start)
          offset.start = 1
        }
        if (offset.end > pageCount) {
          offset.start = offset.start - (offset.end - pageCount)
          offset.end = pageCount
        }
        if (offset.start < 1) offset.start = 1

        this.showPrevMore = (offset.start > 1)
        this.showNextMore = (offset.end < pageCount)

        for (let i = offset.start; i <= offset.end; i++) {
          array.push(i)
        }

        return array
      }
    },
    data () {
      return {
        index : this.pageIndex, //当前页码
        limit : this.pageSize, //每页显示条数
        size : this.total || 1, //总记录数
        showPrevMore : false,
        showNextMore : false
      }
    },
    watch : {
      pageIndex(val) {
        this.index = val || 1
      },
      pageSize(val) {
        this.limit = val || 10
      },
      total(val) {
        this.size = val || 1
      }
    },
    created() {
    }
  }
</script>

<style scoped>
  .mo-paging {
    display: inline-block;
    margin: 20px auto;
    font-size: 0;
    list-style: none;
    user-select: none;
  }

  .mo-paging .paging-item {
    display: inline-block;
    font-size: 14px;
    position: relative;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    border: 1px solid #818184;
    border-radius: 4px;
    background-color: #3a3a3a;
    margin: 2px 1px;
    cursor: pointer;
    color: #ffffff;
  }

  .mo-paging .paging-item:first-child {
    margin-left: 0;
  }
/*

  .paging-item:hover {
    background-color: #505054;
    color: #ffffff;
  }
*/

  .mo-paging .paging-item--disabled,
  .mo-paging .paging-item--more {
    background-color: #3a3a3a;
    color: #ffffff;
  }

  .mo-paging .paging-item--disabled {
    cursor: not-allowed;
    opacity: .75;
  }

  .mo-paging .paging-item--more,
  .paging-item .paging-item--current {
    cursor: default;
  }

  .mo-paging .paging-item--current {
    background-color: #676767;
    color: #fff;
    position: relative;
    z-index: 1;
    border-color: #818184;
  }

</style>